@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: var(--app-custom-informer-display, flex);
  flex-direction: var(--app-custom-informer-direction, row);
  background: var(--app-custom-informer-background);
  padding: var(--app-custom-informer-padding);
  gap: var(--app-custom-informer-gap);
  justify-content: var(--app-custom-informer-justify-content);
  align-items: var(--app-custom-informer-align-items, flex-start);
  border: var(--app-custom-informer-border);
  border-radius: var(--app-custom-informer-border-radius);
  box-shadow: var(--app-custom-informer-box-shadow);
  max-width: var(--app-custom-informer-max-width);

  @include respond-to(sm, up) {
    padding: var(--app-custom-informer-padding-m);
    gap: var(--app-custom-informer-gap-m);
  }

  @include respond-to(md, up) {
    padding: var(--app-custom-informer-padding-l);
    gap: var(--app-custom-informer-gap-l);
  }
}

.icon {
  flex-grow: 0;
  background: var(--app-custom-informer-icon-background);
  padding: var(--app-custom-informer-icon-padding);

  @include respond-to(sm, up) {
    padding: var(--app-custom-informer-icon-padding-m);
  }

  @include respond-to(md, up) {
    padding: var(--app-custom-informer-icon-padding-l);
  }

  svg {
    width: var(--app-custom-informer-svg-size);
    height: var(--app-custom-informer-svg-size);
    border: var(--app-custom-informer-svg-border);
    border-radius: var(--app-custom-informer-svg-border-radius);
  }

  path {
    fill: var(--app-custom-informer-svg-color);
  }
}

.text {
  p {
    color: var(--app-custom-informer-p-colour);
    display: var(--app-custom-informer-p-display);
    font-family: var(--app-custom-informer-p-font-face);
    font-weight: var(--app-custom-informer-p-font-weight);
    letter-spacing: var(--app-custom-informer-p-letter-spacing);
    line-height: var(--app-custom-informer-p-line-height);
    text-transform: var(--app-custom-informer-p-text-transform);

    @include respond-to(sm, up) {
      font-size: var(--app-custom-informer-p-font-size-m);
    }

    @include respond-to(md, up) {
      font-size: var(--app-custom-informer-p-font-size-l);
    }
  }

  ul {
    li {
      color: var(--app-custom-informer-li-color);
      font-size: var(--app-custom-informer-li-font-size);
      line-height: var(--app-custom-informer-li-line-height);
      margin: var(--app-custom-informer-li-margin);

      @include respond-to(sm, up) {
        font-size: var(--app-custom-informer-li-font-size-m);
      }

      @include respond-to(md, up) {
        font-size: var(--app-custom-informer-li-font-size-l);
      }
    }
  }
}
