@import '../resources/vars';
@import '../resources/mixins';

.container {
  // Informer container
  --app-custom-informer-display: var(--coach-explainer-display, flex);
  --app-custom-informer-flex-direction: var(--coach-explainer-direction, row);
  --app-custom-informer-background: var(--coach-explainer-background);
  --app-custom-informer-padding: var(--coach-explainer-padding);
  --app-custom-informer-gap: var(--coach-explainer-gap);
  --app-custom-informer-justify-content: var(--coach-explainer-justify-content);
  --app-custom-informer-align-items: var(--coach-explainer-align-items, flex-start);
  --app-custom-informer-border: var(--coach-explainer-border);
  --app-custom-informer-border-radius: var(--coach-explainer-border-radius);
  --app-custom-informer-box-shadow: var(--coach-explainer-box-shadow);
  --app-custom-informer-max-width: var(--coach-explainer-max-width);

  --app-custom-informer-padding-m: var(--coach-explainer-padding-m);
  --app-custom-informer-gap-m: var(--coach-explainer-gap-m);

  --app-custom-informer-padding-l: var(--coach-explainer-padding-l);
  --app-custom-informer-gap-l: var(--coach-explainer-gap-l);

  // Informer icon
  --app-custom-informer-icon-background: var(--coach-explainer-icon-background);
  --app-custom-informer-icon-padding: var(--coach-explainer-icon-padding);

  --app-custom-informer-icon-padding-m: var(--coach-explainer-icon-padding-m);

  --app-custom-informer-icon-padding-l: var(--coach-explainer-icon-padding-l);

  --app-custom-informer-svg-size: var(--coach-explainer-svg-size);
  --app-custom-informer-svg-border: var(--coach-explainer-svg-border);
  --app-custom-informer-svg-border-radius: var(--coach-explainer-svg-border-radius);
  --app-custom-informer-svg-color: var(--coach-explainer-svg-color);

  // Informer text
  @include rewrite-styled-text-vars(app-custom-informer-p, coach-explainer-p);

  --app-custom-informer-p-font-size-m: var(--coach-explainer-p-font-size-m);
  --app-custom-informer-p-font-size-l: var(--coach-explainer-p-font-size-l);

  // Informer list
  --app-custom-informer-li-color: var(--coach-explainer-li-color);
  --app-custom-informer-li-font-size: var(--coach-explainer-li-font-size);
  --app-custom-informer-li-line-height: var(--coach-explainer-li-line-height);
  --app-custom-informer-li-margin: var(--coach-explainer-li-margin);

  --app-custom-informer-li-font-size-m: var(--coach-explainer-li-font-size-m);
  --app-custom-informer-li-font-size-l: var(--coach-explainer-li-font-size-l);

}
