@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  // Informer container
  --app-custom-informer-display: var(--forms-informer-display, flex);
  --app-custom-informer-flex-direction: var(--forms-informer-direction, row);
  --app-custom-informer-background: var(--forms-informer-background);
  --app-custom-informer-padding: var(--forms-informer-padding);
  --app-custom-informer-gap: var(--forms-informer-gap);
  --app-custom-informer-justify-content: var(--forms-informer-justify-content);
  --app-custom-informer-align-items: var(--forms-informer-align-items, flex-start);
  --app-custom-informer-border: var(--forms-informer-border);
  --app-custom-informer-border-radius: var(--forms-informer-border-radius);
  --app-custom-informer-box-shadow: var(--forms-informer-box-shadow);
  --app-custom-informer-max-width: var(--forms-informer-max-width);

  --app-custom-informer-padding-m: var(--forms-informer-padding-m);
  --app-custom-informer-gap-m: var(--forms-informer-gap-m);

  --app-custom-informer-padding-l: var(--forms-informer-padding-l);
  --app-custom-informer-gap-l: var(--forms-informer-gap-l);

  // Informer icon
  --app-custom-informer-icon-background: var(--forms-informer-icon-background);
  --app-custom-informer-icon-padding: var(--forms-informer-icon-padding);

  --app-custom-informer-icon-padding-m: var(--forms-informer-icon-padding-m);

  --app-custom-informer-icon-padding-l: var(--forms-informer-icon-padding-l);

  --app-custom-informer-svg-size: var(--forms-informer-icon-size);
  --app-custom-informer-svg-border: var(--forms-informer-icon-border);
  --app-custom-informer-svg-border-radius: var(--forms-informer-icon-border-radius);
  --app-custom-informer-svg-color: var(--forms-informer-icon-color);

  // Informer text
  @include rewrite-styled-text-vars(app-custom-informer-p, forms-informer-p);

  --app-custom-informer-p-font-size-m: var(--forms-informer-p-font-size-m);
  --app-custom-informer-p-font-size-l: var(--forms-informer-p-font-size-l);

  // Informer list
  --app-custom-informer-li-color: var(--forms-informer-li-color);
  --app-custom-informer-li-font-size: var(--forms-informer-li-font-size);
  --app-custom-informer-li-line-height: var(--forms-informer-li-line-height);
  --app-custom-informer-li-margin: var(--forms-informer-li-margin);

  --app-custom-informer-li-font-size-m: var(--forms-informer-li-font-size-m);
  --app-custom-informer-li-font-size-l: var(--forms-informer-li-font-size-l);
}

.button {
  // Informer button
  --app-page-actions-button-width: var(--forms-informer-button-width, auto);
}
