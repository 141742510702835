@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: flex;
  justify-content: var(--input-group-align);
  flex-wrap: wrap;
  width: 100%;

  &:not(:first-child) {
    margin-top: var(--input-spacer, var(--coach-spacer-input));
  }
}

.controller {
  display: flex;
  flex-direction: column;
  align-items: var(--input-group-align);
  width: 100%;
}

.label-container {
  width: 100%;
  padding: var(--app-custom-input-controller-padding);
  background: var(--app-custom-input-controller-background);
  border: var(--app-custom-input-controller-border);
  border-radius: var(--app-custom-input-controller-border-radius);
  &, label {
    cursor: pointer;
  }
}

.explainer {
  --app-custom-explainer-background: var(--input-explainer-background-color);
  --app-custom-explainer-margin: var(--input-explainer-margin);
  --app-custom-explainer-padding: var(--input-explainer-padding);

  --app-custom-explainer-order: var(--forms-input-text-order-explainer);

  --app-custom-explainer-label-colour: var(--input-explainer-label-colour);
  --app-custom-explainer-label-font-family: var(--input-explainer-label-font-family);
  --app-custom-explainer-label-font-weight: var(--input-explainer-label-font-weight);
  --app-custom-explainer-label-font-style: var(--input-explainer-label-font-style);
  --app-custom-explainer-label-line-height: var(--input-explainer-label-line-height);
  --app-custom-explainer-label-letter-spacing: var(--input-explainer-label-letter-spacing);
  --app-custom-explainer-label-text-transform: var(--input-explainer-label-text-transform);
  --app-custom-explainer-label-text-align: var(--input-explainer-label-text-align);

  --app-custom-explainer-label-font-size: var(--input-explainer-label-font-size);
  --app-custom-explainer-label-font-size-m: var(--input-explainer-label-font-size-m);
  --app-custom-explainer-label-font-size-l: var(--input-explainer-label-font-size-l);
}

.informer {
  &.modal {
    order: var(--forms-informer-order-modal);
    margin: var(--forms-informer-margin-modal);
  }
  &.static {
    order: var(--forms-informer-order-static);
    margin: var(--forms-informer-margin-static);
  }
}
